<template>
  <div>
    <div
      class="wrapper layout-wrapper h-100"
      :class="{ 'hide-vertical-scroll': hideScrollbar, disable: ongoingOnboarding }"
    >
      <ClientOnly>
        <customize-top-bar v-if="isStylize" @showtoast="showingSavedToast" @applied="appliedTemp" />
      </ClientOnly>
      <ClientOnly>
        <nav-bar v-if="!isStylize && !(isMobSmallScreen && isChatPage)" class="fixed-mobile" />
      </ClientOnly>
      <div class="layout-scroll-area">
        <div class="centered-content-layout">
          <div
            class="main flex-grow-1 d-flex position-relative justify-content-between"
            :class="{ 'set-margin': isChatPage }"
            :style="`justify-content: ${getContentStyle};`"
          >
            <ClientOnly>
              <customize-apply-section
                v-if="isStylize"
                @showtoast="showingSavedToast"
                @createdtoast="showingToast"
                @applied="appliedTemp"
              />
            </ClientOnly>
            <ClientOnly>
              <MainBar
                v-if="!isStylize"
                class="fixed-main-bar"
                :class="{
                  colorize: isCharacterProfile,
                  'hide-vertical-bar': isStylize,
                }"
              />
            </ClientOnly>

            <div
              class="layout position-relative"
              :class="{
                'chat-page': isChatPage,
                'stylize-layout': isStylize,
                'hide-vertical-scroll': isRolechatPage,
              }"
              :style="isStylize ? 'min-width: 650px;max-width: 650px' : ''"
            >
              <div
                id="main-content"
                class="d-flex flex-column flex-grow-1"
                :class="{
                  'color-changed': isChatPage,
                  'customize-margin': isStylize && !isEmpty(getTemplate),
                  'customize-not-applied-margin': isStylize && isEmpty(getTemplate),
                  'customize-try-on-margin':
                    isStylize && get($route.query, 'tempId') && !isEmpty(get(customizeChar, 'template_applied')),
                  'h-100': isChatPage,
                }"
              >
                <div v-if="!get(user, 'is_email_verified', true)">
                  <router-link to="/account/settings" class="sticky-warning">
                    <ion-card class="m-0 warning sticky-warning">
                      <div class="d-flex align-items-center justify-content-center text-color" style="height: 35px">
                        <ion-icon class="mr-1" style="font-size: 18px !important" :icon="warningOutline" />
                        <strong> Verify your email to start sharing! Click here to resend or change your email</strong>
                      </div>
                    </ion-card>
                  </router-link>
                </div>
                <div v-if="!userHasCharacters">
                  <router-link :to="{ name: 'character-creator' }" class="sticky-warning">
                    <ion-card class="m-0 bg-secondary warning sticky-warning">
                      <div class="d-flex align-items-center justify-content-center text-color" style="height: 35px">
                        <ion-icon class="mr-1" style="font-size: 18px !important" :icon="warningOutline" />
                        <strong> Create character to unlock more features.</strong>
                      </div>
                    </ion-card>
                  </router-link>
                </div>

                <slot class="router" />
              </div>
            </div>
            <ClientOnly>
              <div v-if="!isStylize" class="right-placeholder" :style="creationsVisible ? '' : 'max-width:100px'">
                <CreationsBar
                  v-if="!isStylize && isDesktopSize"
                  :style="{ background: bg }"
                  :class="{ colorize: isCharacterProfile, 'hide-placeholder': isStylize }"
                  :isVisible="creationsVisible"
                  @toggle="toggleCreations"
                />
              </div>
            </ClientOnly>

            <ClientOnly>
              <customize-settings-bar
                v-if="isStylize"
                :id="String($route.params.id)"
                @showtoast="showingSavedToast"
                @createdtoast="showingToast"
              />
            </ClientOnly>
          </div>
        </div>

        <ClientOnly>
          <customize-bottom-bar v-if="isStylize" />
        </ClientOnly>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import NavBar from '@/shared/components/nav-bar/NavBar.vue';
import CreationsBar from '@/shared/components/CreationsBar.vue';
import MainBar from '@/shared/components/MainBar.vue';
import CustomizeTopBar from '@/shared/components/customize-top-bar.vue';
import CustomizeApplySection from '@/shared/components/customize-apply-section.vue';
import CustomizeBottomBar from '@/shared/components/customize-bottom-bar.vue';
import CustomizeSettingsBar from '@/shared/components/customize-settings-bar.vue';
import { authStore } from '@/shared/pinia-store/auth';
import { characterStylizeStore } from '@/shared/pinia-store/character-stylize';
import { warningOutline } from 'ionicons/icons';
import { onboardingStore } from '@/shared/pinia-store/onboarding';
import { mainStore } from '@/shared/pinia-store/main';

const { customizeCharacter, template } = characterStylizeStore();
const { updateCreatedToast, updateSavedToast, updateTempApp, isSavedToast, isCreatedToast, user, userHasCharacters } =
  authStore();
const { width } = useWindowSize();
const isApplied = ref(false);

const { ongoingOnboarding } = onboardingStore();

const defaultPageRoute = computed(() => {
  try {
    const router = useRouter();
    return router.currentRoute.value.name;
  } catch (error) {
    return '';
  }
});

const isMobSmallScreen = computed(() => {
  return width.value < 570;
});

const isTabletScreen = computed(() => {
  return width.value >= 1024 && width.value < 1365;
});

const getContentStyle = computed(() => {
  return isStylize.value && isTabletScreen.value ? 'normal' : 'center ';
});

const customizeChar = computed(() => {
  return customizeCharacter.value;
});

const getTemplate = computed(() => {
  return template.value;
});

const { isDesktopSize } = useWindowSize();

const showingToast = (isToast: boolean) => {
  updateCreatedToast(isToast);
  setTimeout(() => {
    updateCreatedToast(false);
  }, 1000);
};

const showingSavedToast = (saved: boolean) => {
  updateSavedToast(saved);
  setTimeout(() => {
    updateSavedToast(false);
  }, 2600);
};

const appliedTemp = () => {
  isApplied.value = true;
  updateTempApp(isApplied.value);
  setTimeout(() => {
    updateTempApp(false);
  }, 500);
};

const isStylize = computed(() => {
  return defaultPageRoute.value === 'character-stylize' || defaultPageRoute.value === 'character-draft-stylize';
});

const { text: t, bg: b, isCharacterProfile } = useCharPageColors();

const text = computed(() => t.value.replace(' !important', ''));
const bg = computed(() => b.value.replace(' !important', ''));

const { isAuthenticated, updateScrollHandleAtBottom, updateCreationsToggleVisibility, creationsVisible } = authStore();
const { dark } = mainStore();
const isChatPage = computed(() => {
  return defaultPageRoute.value === 'social-space-chatroom';
});

const wrapperLayoutBg = computed(() => {
  const defaultColor = dark.value ? '#010123' : '#fff';
  return isCharacterProfile.value ? bg.value : defaultColor;
});
const layoutBg = computed(() => {
  const defaultColor = dark.value ? '#010123' : '#f3f3f3';
  return isCharacterProfile.value ? bg.value : defaultColor;
});

const extraLargeScreenlayout = computed(() => {
  return creationsVisible.value ? '1000px' : '1190px';
});
const largeScreenLayout = computed(() => {
  return creationsVisible.value ? 'calc(100vw - 544px)' : 'calc(100vw - 355px)';
});
const tabLayout = computed(() => {
  return creationsVisible.value ? 'calc(100vw - 500px)' : 'calc(100vw - 343px)';
});
const isRolechatPage = computed(() => {
  return defaultPageRoute.value === 'rolechat-room';
});

const hideScrollbar = computed(() => {
  return isChatPage.value;
});

const layoutScrollArea = ref();
const creationsBar = ref();
const route = useRoute();

const scrollCb = () => {
  layoutScrollArea.value = document.querySelector('.layout-scroll-area');
  creationsBar.value = document.querySelector('.right-placeholder');
  if (layoutScrollArea.value && route.name === 'quizzes') {
    const scrolloffset = 20;
    if (
      layoutScrollArea.value.scrollTop >=
      layoutScrollArea.value.scrollHeight - (layoutScrollArea.value.clientHeight + scrolloffset)
    ) {
      updateScrollHandleAtBottom(true);
    } else {
      updateScrollHandleAtBottom(false);
    }
  }

  if ((layoutScrollArea.value, creationsBar.value)) {
    const maxCreationsBarScrollHeight = creationsBar.value.scrollHeight;
    const layoutScrollAreaScrollTop = layoutScrollArea.value.scrollTop;

    if (layoutScrollAreaScrollTop > maxCreationsBarScrollHeight) return;

    creationsBar.value.scrollTo({ top: layoutScrollAreaScrollTop });
  }
};

const toggleCreations = () => {
  if (creationsVisible.value) {
    updateCreationsToggleVisibility(false);
    return;
  }

  if (!creationsVisible.value) {
    updateCreationsToggleVisibility(true);
    return;
  }
};
const scrollInit = () => {
  setTimeout(() => {
    layoutScrollArea.value = document.querySelector('.layout-scroll-area');
    creationsBar.value = document.querySelector('.right-placeholder');

    try {
      layoutScrollArea.value.addEventListener('scroll', scrollCb);
    } catch (error) {}
  });
};

onBeforeUnmount(() => {
  if (layoutScrollArea.value) {
    layoutScrollArea.value.removeEventListener('scroll', scrollCb);
  }
});

onMounted(() => {
  if (isSavedToast.value) {
    updateSavedToast(false);
  } else if (isCreatedToast.value) {
    updateCreatedToast(false);
  }

  scrollInit();
  updateScrollHandleAtBottom(false);
});

watch(isAuthenticated, () => {
  scrollInit();
});
</script>

<style scoped lang="sass">
.fixed-main-bar
  position: sticky
  top: 0
  z-index: 100
  height: calc(var(--doc-height) - var(--default-nav-bar-height))
  overflow-y: hidden !important
  overflow-x: hidden !important

.centered-content-layout
  margin-right: auto !important
  margin-left: auto !important
  max-width: 1580px
.text-color
  color: black

.stylize-layout
  height: 100vh !important
  width: 100vw
  justify-content: center
.footer
  margin-top: -100px
  ::v-deep
    .footer-content
      background: #f3f3f3
.right-placeholder
  //width: calc((100vw - 170px) * 0.5)
  height: calc(var(--doc-height) - var(--default-nav-bar-height))
  box-shadow: -8px 7px 8px 1px rgba(35, 14, 51, 0.1)
  background: #FFF
  position: sticky
  top: 0
  align-self: flex-start
  overflow-y: hidden
  overflow-x: hidden
.layout-scroll-area
  overflow-x: hidden
  overflow-y: auto
  height: calc(var(--doc-height) - var(--default-nav-bar-height))
.colorize
  color: v-bind(text) !important
  background: v-bind(bg) !important
  ::v-deep
    .menu-btn
      .action-area
        color: v-bind(text) !important
    .profile-name
      color: v-bind(text) !important
    .user-area, .user-area-wrap
      background: v-bind(bg) !important
    .button-clear, .toggle-btn
      color: v-bind(text) !important
    .one-creation
      .name
        color: v-bind(text) !important
    .folder, .action-area, .footer-btn, .other-btn, .inline-button
      color: v-bind(text) !important
      .s1
        fill: v-bind(text) !important
      path
        stroke: v-bind(text) !important
        fill: transparent !important
      rect
        fill: transparent !important
        stroke: v-bind(text) !important

#main-content
  justify-content: space-between
  overflow: visible
.wrapper
  max-height: 100%
  overflow: hidden
  display: flex
  flex-direction: column
  background: v-bind(wrapperLayoutBg)


.layout-wrapper
  height: 100vh
  width: 100vw

.main
  overflow-y: scroll
  margin: 0
  background: v-bind(layoutBg)
.main, .layout, .wrapper
  overflow: visible !important
.footer-cover
  background-color: #ae44d4
  height: 50px
  position: absolute
  bottom: -5px
  width: 100%
  &.dark
    background-color: #11022a
    height: 80px

.layout
  width: 100%
  max-width: v-bind(extraLargeScreenlayout)
  min-width: v-bind(largeScreenLayout)
  background: v-bind(layoutBg) !important
  @media(max-width:1540px)
    max-width: v-bind(largeScreenLayout) !important
    min-width: v-bind(largeScreenLayout) !important
  @media(max-width:1280px) and (min-width:1184px)
    max-width: calc(100vw - 500px) !important
    min-width: calc(100vw - 500px) !important
  overflow: visible !important
  &:not(.w-100)
    max-width: v-bind(extraLargeScreenlayout)
    min-width: v-bind(extraLargeScreenlayout)
    background: v-bind(layoutBg) !important
    @media(max-width:1540px)
      max-width: v-bind(largeScreenLayout) !important
      min-width: v-bind(largeScreenLayout) !important
    @media(max-width:1280px) and (min-width:1184px)
      max-width: v-bind(tabLayout) !important
      min-width: v-bind(tabLayout) !important
  &.chat-page
    padding-top: 0 !important
  ::v-deep
    .router, .page
      position: relative
    .router
      width: 100%
      max-width: 820px
      margin: auto
      z-index: 11
    .page
      display: flex
      flex-direction: column
      flex-grow: 1
      margin-bottom: auto !important
      overflow: visible !important

@media(min-width:1024px) and (max-width:1215px)
  .stylize-layout
    &.layout
      margin-right: 260px !important

@media(min-width:1024px) and (max-width:1365px)
  .stylize-layout
    max-width: 100vw
    min-width: 100vw
    justify-content: center
  .main
    width: 100%
  .layout
    margin: 0 !important

  .fixed-mobile
    position: sticky !important
    top: 0
    z-index: 112
    width: 100%

.color-changed
  background: #c6c8d6 !important
.dark .color-changed
  background: #17074C !important
.customize-not-applied-margin
  margin-top: 64px
  @media(max-width:1365px)
    margin-top: 123px !important
  @media(max-width:570px)
    margin-top: 7px !important
.hide-placeholder
  display: none !important
.set-margin
  margin-top: 0px !important
.hide-vertical-scroll
  overflow-y: hidden !important
.hide-vertical-bar
  display: none !important
.customize-margin
  margin-top: 63px !important
  @media(max-width: 1365px)  and (min-width:570px)
    margin-top: 243px !important
    margin-bottom: 10px !important
.customize-try-on-margin
  @media(max-width: 1365px) and (min-width:570px)
    margin-top: 265px !important

.pb-5
  padding-bottom: 70px !important
</style>
